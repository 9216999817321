import React, { CSSProperties, useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import {
  products as mainProduct,
  medicineTypes,
  properties,
  brands,
} from '../../db/products';
import { palette, color, images, spacing } from '@/Layouts/theme';
import { CardProduct, CardDescription } from '../../components';
import { graphql, Link, navigate } from 'gatsby';
import { Icon } from 'react-icons-kit';
import { priceTag } from 'react-icons-kit/icomoon/priceTag';
import { arrows_slim_right } from 'react-icons-kit/linea/arrows_slim_right';
import { useTranslation } from 'react-i18next';
import {
  MOBILE_HEADER_TH,
  MOBILE_HEADER_EN,
  HEADER_TH,
  HEADER_EN,
  BODY_3_EN,
  BODY_3_TH,
  BODY_1_EN,
  BODY_1_TH,
  BODY_7_EN,
  BODY_7_TH,
  NON_PADDING_LEFT_RIGHT,
  SpaceBetweenContent,
  BUTTON_STYLE,
} from '../../Layouts/styled/main';
import EndingContent from '../../Layouts/Footer/ending-content';
import i18next from 'i18next';
import { getSourceImage } from '@/utils/image-sharp';
import { Button } from '@material-ui/core';
import styled from 'styled-components';

type Position = {
  position: 'flex-start' | 'center' | 'flex-end';
};

const BREADCRUMB: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  paddingTop: spacing[7],
  paddingBottom: spacing[3],
};

const HEADER: CSSProperties = {
  paddingTop: spacing[5],
  paddingBottom: spacing[5],
};

const DESCRIPTION: CSSProperties = {
  paddingTop: spacing[4],
  paddingBottom: spacing[4],
};

const LINE_DEFAULT: CSSProperties = {
  borderTop: `1.5px solid ${color.mainColor4}`,
};

const LINE_MAIN: CSSProperties = {
  display: 'flex',
  width: '100%',
  height: 3,
  backgroundColor: color.darkGray,
  marginBottom: spacing[4],
};

const LINE_OUTSIDE: CSSProperties = {
  width: '33.33333%',
  backgroundColor: color.mainColor4,
};

const BUTTON_CONTAINER: CSSProperties = {
  marginTop: spacing[7],
  marginBottom: spacing[7],
};

const PRODUCT_DESCRIPTION: CSSProperties = {
  paddingTop: spacing[3],
  marginBottom: 'auto'
};

const BOTTOM_DESCRIPTION: CSSProperties = {
  display: 'flex',
  alignItems: 'flex-end',
};

const ARROW: CSSProperties = {
  color: color.mainColor4,
  // position: 'absolute',
  bottom: 0,
  right: 15,
  zIndex: 2,
};

const LINK_TO: CSSProperties = {
  textDecoration: 'none',
  color: color.textBlack,
  display: 'flex',
  flexDirection: 'column',
  height: '100%'
};

const RELATED_PRODUCT: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
};

const BORDER_TAG: CSSProperties = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 45,
  height: 45,
  borderRadius: '50%',
  backgroundColor: color.mainColor5,
  padding: spacing[2],
};

const RELATED_PRODUCT_LIST: CSSProperties = {
  marginBottom: 30,
};

const TAG: CSSProperties = {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
};

const LineLength = ({ position }: Position) => {
  return (
    <div style={{ ...LINE_MAIN, justifyContent: position }}>
      <div style={LINE_OUTSIDE}></div>
    </div>
  );
};

const Tags = ({ path, text, id, size = 'lg' }) => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingRight: size === 'lg' && id != 2 ? spacing[3] : undefined,
        paddingTop: size === 'xs' && id != 0 ? spacing[3] : undefined,
        flex: 1,
      }}
    >
      <span style={BORDER_TAG}>
        {path ? (
          <img
            src={require(`../../images${path}`).default}
            width={25}
            height={25}
          />
        ) : (
          <Icon icon={priceTag} size={25} style={{ color: color.mainColor5 }} />
        )}
      </span>
      <p
        style={{
          margin: 0,
          paddingLeft: spacing[2],
          ...(i18next.language === 'th' ? BODY_7_TH : BODY_7_EN),
        }}
      >
        {text}
      </p>
    </div>
  );
};

const ProductDescription = ({
  size = 'lg',
  textTopStyle,
  productDetail,
  components,
  t,
}) => (
  <>
    <div style={DESCRIPTION}>
      <span style={textTopStyle}>{t('benefit')}</span>
      <LineLength position={'flex-start'} />
      {/* <p
        style={{
          ...(i18next?.language === 'th' ? BODY_7_TH : BODY_7_EN),
          margin: 0,
        }}
      >
        {productDetail && productDetail[`full_details_${i18next.language}`]}
      </p> */}
      <div
        dangerouslySetInnerHTML={{
          __html: productDetail
            ? productDetail[`full_details_${i18next.language}`]
            : null,
        }}
        style={{ ...(i18next?.language === 'th' ? BODY_7_TH : BODY_7_EN) }}
      />
    </div>
    <div style={DESCRIPTION}>
      <span style={textTopStyle}>{t('dosage')}</span>
      <LineLength position={'center'} />
      <p
        style={{
          ...(i18next?.language === 'th' ? BODY_7_TH : BODY_7_EN),
          margin: 0,
        }}
      >
        {productDetail && productDetail[`how_to_use_${i18next.language}`]}
      </p>
    </div>
    <div style={DESCRIPTION}>
      <span style={textTopStyle}>{t('caution')}</span>
      <p
        style={{
          ...(i18next?.language === 'th' ? BODY_7_TH : BODY_7_EN),
          margin: 0,
        }}
      >
        {(productDetail &&
          productDetail[`additional_help_${i18next.language}`]) ||
          '-'}
      </p>
    </div>
    <div
      style={{
        ...DESCRIPTION,
        paddingBottom: size === 'xs' ? spacing[6] : spacing[7],
      }}
    >
      <span style={textTopStyle}>{t('ingredients')}</span>
      <LineLength position={'flex-end'} />
      {components?.map((comp: any, index: number) => (
        <p
          key={index}
          style={{
            ...(i18next?.language === 'th' ? BODY_7_TH : BODY_7_EN),
            marginBottom: spacing[2],
          }}
        >
          {comp}
        </p>
      ))}
    </div>
  </>
);

const ProductImage = ({ size = 'lg', productDetail, data }) => (
  <div data-aos={'fade-left'}>
    <CardProduct
      source={getSourceImage(
        productDetail?.product_image_path_1
          ? productDetail.product_image_path_1
          : 'no-img.png',
        data,
      )}
      containerStyle={{
        borderRadius: size === 'xs' ? '80px 0px' : '110px 0px',
        background: productDetail?.theme_code
          ? `linear-gradient(180deg, ${palette.productSet1[`${productDetail.theme_code}1`]
          } 60%, ${palette.productSet1[`${productDetail.theme_code}2`]} 40%)`
          : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
      }}
    // containerImageStyle={size === 'xs' ? { maxWidth: '65%' } : {}}
    />
  </div>
);

const ProductDetail: React.FC<any> = (props: any) => {
  const { t } = useTranslation();
  const [screenSize, setScreenSize] = useState<any>({ height: 0, width: 0 });
  const [hasRan, setHasRan] = useState<boolean>(false);
  const [productDetail, setProductDetail] = useState<any>(null);
  const [components, setComponents] = useState<Array<string>>(null);
  const [arrRelated, setArrRelated] = useState<Array<string>>(null);
  const [relatedProducts, setRelatedProducts] = useState<Array<any>>(null);
  const [tags, setTags] = useState<any>([]);

  const updateScreenSize = () => {
    setScreenSize({ width: window.innerWidth, height: window.innerHeight });
  };

  useEffect(() => {

    return () => {
      setProductDetail(null);
    };
  }, []);

  useEffect(() => {
    if (!hasRan) {
      setHasRan(true);
      updateScreenSize();
    }
    window.addEventListener('resize', updateScreenSize);
    return () => {
      window.removeEventListener('resize', updateScreenSize);
    };
  }, [screenSize]);

  useEffect(() => {
    if (props?.productId || i18next.language) {
      const product = mainProduct.filter(
        (product: any) => product.product_code === props?.productId,
      )[0];
      const brand = brands.filter((val) => val.id === product.brand_id)[0];
      const propertyType = properties.filter(
        (val) => val.properties_id === product.properties_id?.split(',')[0],
      )[0];
      const medicine = medicineTypes.filter(
        (val) => val.id === product.medicine_type_id,
      )[0];

      const tags = [
        // {
        //   id: 1,
        //   name: brand ? brand[`name_${i18next.language}`] : '-',
        //   path: brand ? `${brand.rootPath}/${brand.image}` : null,
        // },
        {
          id: 2,
          name: medicine ? medicine[`name_${i18next.language}`] : '-',
          path: medicine ? `${medicine.rootPath}/${medicine.image}` : null,
        },
        {
          id: 3,
          name: propertyType
            ? propertyType[`properties_name_${i18next.language}`]
            : '-',
          path: propertyType
            ? `${propertyType.rootPath}/${propertyType.image}`
            : null,
        },
      ];

      const components = [
        product[`component_1_${i18next.language}`],
        product[`component_2_${i18next.language}`],
        product[`component_3_${i18next.language}`],
        product[`component_4_${i18next.language}`],
        product[`component_5_${i18next.language}`],
        product[`component_6_${i18next.language}`],
      ].filter(Boolean);

      const relatedProducts = [
        product['related_product_code_1'],
        product['related_product_code_2'],
        product['related_product_code_3'],
        product['related_product_code_4'],
      ].filter(Boolean);

      setProductDetail(product);
      setComponents(components);
      setArrRelated(relatedProducts);
      setTags(tags);
    }
  }, [props?.productId, i18next.language]);

  useEffect(() => {
    if (arrRelated?.length) {
      const relatedProducts = mainProduct.filter((product: any) =>
        arrRelated.includes(product.product_code),
      );
      setRelatedProducts(relatedProducts);
    }
  }, [arrRelated?.length && JSON.stringify(arrRelated)]);

  const TEXT_TOP: CSSProperties = {
    ...(i18next?.language === 'th' ? BODY_3_TH : BODY_3_EN),
    fontWeight: 'bold',
  };

  const BUTTON: CSSProperties = {
    ...BUTTON_STYLE,
    backgroundColor: color.mainColor5,
    borderColor: color.mainColor5,
    textDecoration: 'none',
    fontFamily: i18next?.language === 'th' ? 'KanitLight' : 'RobotaRegular',
  };

  const edges = props.data.allFile.edges;

  return (
    <main className={'main'}>
      {/* //? for screen size xs-md */}
      <section className="d-md-none d-block">
        <Container style={{ maxWidth: 1080 }}>
          <Row className="justify-content-between">
            <Col xs={1} />
            <Col xs={10}>
              <div style={BREADCRUMB}>
                <BreadcrumbMain
                  style={{
                    paddingRight: spacing[2],
                    marginLeft: -30,
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                  }}
                  onClick={() => navigate('/product')}
                >{`${t('product')}`}</BreadcrumbMain>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetail
                      ? productDetail[`product_name_${i18next.language}`]
                      : null,
                  }}
                  style={{
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                    borderLeft: '1px solid black',
                    paddingLeft: spacing[2],
                    color: color.mainColor4,
                  }}
                />
              </div>
            </Col>
            <Col xs={1} />
            <Col xs={12} style={{ paddingBottom: 15, paddingTop: spacing[5] }}>
              <ProductImage
                size="xs"
                productDetail={productDetail}
                data={edges}
              />
            </Col>

            <Col xs={1} />
            <Col xs={10}>
              <div style={HEADER}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetail
                      ? productDetail[`product_name_${i18next.language}`]
                      : null,
                  }}
                  style={
                    i18next?.language === 'th'
                      ? MOBILE_HEADER_TH
                      : MOBILE_HEADER_EN
                  }
                />
              </div>
              <ProductDescription
                size="xs"
                textTopStyle={TEXT_TOP}
                productDetail={productDetail}
                components={components}
                t={t}
              />
            </Col>
            <Col xs={1} />
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <LineLarge />

              <div style={{ ...TAG, flexDirection: 'column' }}>
                {tags.map((tag: any, index: number) => (
                  <Tags
                    size="xs"
                    key={index}
                    id={index}
                    path={tag.path}
                    text={tag.name}
                  />
                ))}
              </div>

              <div
                style={{
                  paddingTop: spacing[6],
                  paddingBottom: spacing[6],
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <a
                  target="_blank"
                  href={
                    productDetail?.url_cart || 'https://ouayun.bentoweb.com/th'
                  }
                  style={BUTTON}
                >
                  {t('addToCart')}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <hr style={LINE_DEFAULT} />
            </Col>
          </Row>

          <Row style={{ paddingTop: spacing[6] }}>
            <Col xs={{ span: 11, offset: 1 }}>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingBottom: 30,
                  ...(i18next.language === 'th' ? BODY_3_TH : BODY_3_EN),
                }}
              >
                {t('relateProduct')}
              </div>
            </Col>
            <Col xs="12" style={RELATED_PRODUCT}>
              {relatedProducts?.map((product: any, index: number) => {
                return (
                  <Col key={index} xs={6} style={RELATED_PRODUCT_LIST}>
                    <Link
                      to={`/product/${product.product_code}`}
                      style={LINK_TO}
                      state={{
                        productType: props?.location?.state?.productType,
                      }}
                    >
                      <div>
                        <CardProduct
                          source={getSourceImage(
                            product?.product_image_path_1
                              ? product.product_image_path_1
                              : 'no-img.png',
                            edges,
                          )}
                          containerStyle={{
                            borderRadius: '40px 0px',
                            background: product?.theme_code
                              ? `linear-gradient(180deg, ${palette.productSet1[`${product.theme_code}1`]
                              } 60%, ${palette.productSet1[`${product.theme_code}2`]
                              } 40%)`
                              : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                          }}
                          disableAos
                        />
                      </div>
                      <div style={PRODUCT_DESCRIPTION}>
                        <div
                          dangerouslySetInnerHTML={{
                            __html: product[`product_name_${i18next.language}`],
                          }}
                          style={{
                            ...(i18next.language === 'th'
                              ? BODY_3_TH
                              : BODY_3_EN),
                            marginBottom: spacing[1],
                          }}
                        />
                        <div style={BOTTOM_DESCRIPTION}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                product[
                                `details_paragraph_1_${i18next.language}`
                                ],
                            }}
                            style={{
                              flex: 1,
                              paddingBottom: 5,
                              ...(i18next.language === 'th'
                                ? BODY_7_TH
                                : BODY_7_EN),
                            }}
                          />
                        </div>
                      </div>
                      <Icon icon={arrows_slim_right} size={30} style={ARROW} />
                    </Link>
                  </Col>
                );
              })}
            </Col>
            <Col className="d-none d-md-block" />
            <Col xs={{ span: 10, offset: 1 }} style={NON_PADDING_LEFT_RIGHT}>
              <hr style={{ ...LINE_DEFAULT, margin: 0 }} />
            </Col>
          </Row>
        </Container>
      </section>

      {/* //? for screen size md++ */}
      <section className="d-none d-md-block">
        <Container style={{ maxWidth: 1080 }}>
          <Row className="justify-content-between">
            <Col xl={{ span: 6, offset: 0 }} md={{ span: 5, offset: 1 }}>
              <div style={BREADCRUMB}>
                <BreadcrumbMain
                  style={{
                    paddingRight: spacing[2],
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                  }}
                  onClick={() => navigate('/product')}
                >{`${t('product')}`}</BreadcrumbMain>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetail
                      ? productDetail[`product_name_${i18next.language}`]
                      : null,
                  }}
                  style={{
                    ...(i18next?.language === 'th' ? BODY_1_TH : BODY_1_EN),
                    borderLeft: '1px solid black',
                    paddingLeft: spacing[2],
                    color: color.mainColor4,
                  }}
                />
              </div>
              <div style={HEADER}>
                <div
                  dangerouslySetInnerHTML={{
                    __html: productDetail
                      ? productDetail[`product_name_${i18next.language}`]
                      : null,
                  }}
                  style={i18next?.language === 'th' ? HEADER_TH : HEADER_EN}
                />
              </div>

              <ProductDescription
                textTopStyle={TEXT_TOP}
                productDetail={productDetail}
                components={components}
                t={t}
              />
            </Col>
            <Col
              xl={{ offset: 0, span: 6 }}
              md={{ offset: 1, span: 5 }}
              style={{
                maxWidth: 480,
                ...(screenSize.width < 1200 ? { paddingRight: 0 } : undefined),
              }}
            >
              <ProductImage productDetail={productDetail} data={edges} />
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 6, offset: 0 }} md={{ span: 5, offset: 1 }}>
              <LineLarge />

              <div style={TAG}>
                {tags.map((tag: any, index: number) => (
                  <Tags
                    key={index}
                    id={index}
                    path={tag.path}
                    text={tag.name}
                  />
                ))}
              </div>

              <div style={BUTTON_CONTAINER}>
                <a
                  target="_blank"
                  href={
                    productDetail?.url_cart || 'https://ouayun.bentoweb.com/th'
                  }
                  style={BUTTON}
                >
                  {t('addToCart')}
                </a>
              </div>
            </Col>
          </Row>
          <Row>
            <Col xl={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
              <hr style={LINE_DEFAULT} />
            </Col>
          </Row>

          <Row style={{ paddingTop: spacing[6] }}>
            <Col xl={{ span: 2, offset: 0 }} md={{ span: 2, offset: 1 }}>
              <div
                style={{
                  fontWeight: 'bold',
                  paddingBottom: 40,
                  ...(i18next.language === 'th' ? BODY_3_TH : BODY_3_EN),
                }}
              >
                {t('relateProduct')}
              </div>
            </Col>
            <Col xl={10} md={8} style={RELATED_PRODUCT} data-aos="fade-up">
              {relatedProducts
                ?.slice(0, 3)
                .map((product: any, index: number) => {
                  return (
                    <Col key={index} md={4} xs={6} style={RELATED_PRODUCT_LIST}>
                      <Link
                        to={`/product/${product.product_code}`}
                        style={LINK_TO}
                        state={{
                          productType: props?.location?.state?.productType,
                        }}
                      >
                        <div>
                          <CardProduct
                            source={getSourceImage(
                              product?.product_image_path_1
                                ? product.product_image_path_1
                                : 'no-img.png',
                              edges,
                            )}
                            containerStyle={{
                              borderRadius: '60px 0px',
                              background: product?.theme_code
                                ? `linear-gradient(180deg, ${palette.productSet1[
                                `${product.theme_code}1`
                                ]
                                } 60%, ${palette.productSet1[
                                `${product.theme_code}2`
                                ]
                                } 40%)`
                                : 'linear-gradient(180deg, #dbd9d4 60%, #e7e5e0 40%)',
                            }}
                            disableAos
                          />
                        </div>
                        <div style={PRODUCT_DESCRIPTION}>
                          <div
                            dangerouslySetInnerHTML={{
                              __html:
                                product[`product_name_${i18next.language}`],
                            }}
                            style={{
                              ...(i18next.language === 'th'
                                ? BODY_3_TH
                                : BODY_3_EN),
                              marginBottom: 6,
                            }}
                          />
                          <div style={BOTTOM_DESCRIPTION}>
                            <div
                              dangerouslySetInnerHTML={{
                                __html:
                                  product[
                                  `details_paragraph_1_${i18next.language}`
                                  ],
                              }}
                              style={{
                                flex: 1,
                                paddingBottom: 5,
                                ...(i18next.language === 'th'
                                  ? BODY_7_TH
                                  : BODY_7_EN),
                              }}
                            />
                          </div>
                        </div>
                        <Icon
                          icon={arrows_slim_right}
                          size={30}
                          style={ARROW}
                        />
                      </Link>
                    </Col>
                  );
                })}
            </Col>
            <Col lg={0} md={1} className="d-none d-md-block" />
            <Col xl={{ span: 12, offset: 0 }} md={{ span: 10, offset: 1 }}>
              <hr style={LINE_DEFAULT} />
            </Col>
          </Row>
        </Container>
      </section>

      <SpaceBetweenContent />
      <EndingContent />

      <SpaceBetweenContent className="d-md-none d-block" />
    </main>
  );
};
export default ProductDetail;

const BreadcrumbMain = styled.span`
  cursor: pointer;
`

const LineLarge = styled.hr`
  border-top: 3px solid ${color.mainColor4};
`

export const query = graphql`
  query {
    allFile(filter: { relativePath: { regex: "/product/" } }) {
      edges {
        node {
          childImageSharp {
            fluid(maxWidth: 335, quality: 100) {
              originalName
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`;
