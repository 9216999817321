import React, { useEffect, useState, CSSProperties, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import {
  HEADER_TH,
  HEADER_EN,
  BODY_2_TH,
  BODY_2_EN,
  NON_PADDING_LEFT_RIGHT,
  MOBILE_CONTENT_CARD,
  LeftImage,
  ImageMobileBox,
} from '../styled/main';
import { color, images, spacing } from '../../Layouts/theme';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

interface ContentProps { }

const EndingContent: React.FC<ContentProps> = ({ }) => {
  const { t } = useTranslation();



  return (
    <>
      <div className="is-not-hd-size">
        {/* //? for screen size xs-md */}
        <section className="d-md-none d-block">
          <Container fluid>
            <Row className="justify-content-center">
              <Col xs="12">
                <ImageMobileBox data-aos="fade-right">
                  <LeftImage src={images.consult} alt={'consult-thai-doctor'} />
                </ImageMobileBox>
              </Col>
              <Col xs="1" />
              <Col xs="10">
                <Container
                  fluid
                  style={{ paddingTop: 40, alignContent: 'center' }}
                >
                  <Row>
                    <Col xs="12" className="text-spacing-l-r">
                      <div data-aos="fade-up">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('consult_doctor_title'),
                          }}
                          style={
                            i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                          }
                        />
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('consult_doctor_description'),
                          }}
                          style={
                            i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs="1" />
            </Row>
          </Container>
        </section>

        {/* //? for screen size md++ */}
        <section
          className="d-none d-md-block"
          style={{ marginBottom: -spacing[8] }}
        >
          <Container fluid>
            <Row className="justify-content-center">
              <Col md="6" style={{ paddingLeft: spacing[0] }}>
                <div
                  data-aos="fade-right"
                  style={{ width: '100%', height: 'auto' }}
                >
                  <LeftImage src={images.consult} alt={'consult-thai-doctor'} />
                </div>
              </Col>
              <Col xs="1" />
              <Col md="4">
                <Container
                  fluid
                  style={{
                    paddingTop: spacing[7],
                    alignContent: 'center',
                    height: '100%',
                  }}
                >
                  <Row>
                    <Col xs="12">
                      <div data-aos="fade-up">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('consult_doctor_title'),
                          }}
                          style={
                            i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                          }
                        />
                        <br />
                        <div
                          dangerouslySetInnerHTML={{
                            __html: t('consult_doctor_description'),
                          }}
                          style={
                            i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                          }
                        />
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Col>
              <Col xs="1" />
            </Row>
          </Container>
        </section>
      </div>

      <section className="hd-size" style={{ marginBottom: -spacing[8] }}>
        <Container fluid>
          <Row className="justify-content-center">
            <Col
              xs="auto"
              data-aos="fade-left"
              style={{ width: 480, height: 480 }}
            >
              <LeftImage src={images.consult} alt={'consult-thai-doctor'} />
            </Col>
            <Col xs="auto" style={{ width: 120 }} />
            <Col xs="auto" style={{ width: 480 }}>
              <Container
                fluid
                style={{
                  paddingTop: spacing[7],
                  alignContent: 'center',
                  height: '100%',
                }}
              >
                <Row>
                  <Col
                    xs="auto"
                    style={{ ...NON_PADDING_LEFT_RIGHT, width: 360 }}
                  >
                    <div data-aos="fade-up">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('consult_doctor_title'),
                        }}
                        style={
                          i18next?.language === 'th' ? HEADER_TH : HEADER_EN
                        }
                      />
                      <br />
                      <div
                        dangerouslySetInnerHTML={{
                          __html: t('consult_doctor_description'),
                        }}
                        style={
                          i18next?.language === 'th' ? BODY_2_TH : BODY_2_EN
                        }
                      />
                    </div>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};
export default EndingContent;
